import { useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import URL, { buildRootUrl } from '@/common/app/utils/urlsUtils';
import { TPropsAttractionsPage } from '@/entities/Attractions/domain/Attractions.domain';
import Layout from '@/shared/Layout/ui/Layout';
import AttractionsPageMeta from './Meta/Meta';
import { addDays } from 'date-fns';
import { getLS, setLS } from '@/common/service/storage';
import { useAppContext } from '@/common/app/contexts';
import { getSearchRecents } from '@/common/app/utils/getSearchRecents';
import { IAttractionsSearch } from '@/common/app/contexts/AttractionsContext';
import { trackAttractions } from '@/common/app/utils/iterable';
import { AttractionsList } from './AttractionsList';
import { createListNameGA3 } from '@/common/service/analytics/creators';

export const AttractionsPage = ({
    meta,
    breadcrumbs,
    featuredCategories,
    currentDestination,
    title,
    featuredActivitiesTitle,
    faq,
    fullList,
    isEmpty,
    currentCategory,
    landmarks,
    categoryDescription,
    availableAttributes,
    noRecommends,
    placeholders,
}: TPropsAttractionsPage) => {
    const { asPath } = useRouter();
    const listNameGA3 = createListNameGA3(currentDestination, currentCategory);
    const location = asPath.split('?')[0];

    const { destinations } = useAppContext();

    useEffect(() => {
        const lsRecents = getLS('recents') || [];

        const newDestination = destinations.find(
            (destination) => String(destination.id) === String(currentDestination.id)
        );

        if (!newDestination?.total_activities) {
            return;
        }

        const newRecentDestination = {
            destination: {
                ...newDestination,
                url: asPath,
            },
            dateRange: {
                dateStart: new Date(),
                dateEnd: addDays(new Date(), 6),
            },
        };

        const existsRecent = lsRecents?.find(
            (item: IAttractionsSearch) => item.destination.id === newDestination.id
        );

        if (
            existsRecent &&
            (existsRecent?.category ||
                existsRecent?.partner ||
                existsRecent?.dateRange.dateStart !== newRecentDestination.dateRange.dateStart ||
                existsRecent?.dateRange.dateEnd !== newRecentDestination.dateRange.dateEnd)
        ) {
            return;
        }

        const newRecents = getSearchRecents([newRecentDestination, ...lsRecents]);

        setLS('recents', newRecents);
    }, [currentDestination.id, destinations, asPath]);

    const pageDestination = {
        slug: currentDestination?.slug,
        url_search: currentDestination?.url_search,
        name: currentDestination?.name,
        stateCode: currentDestination?.state?.code,
    };

    const openGraphImage =
        currentCategory && fullList.items[0]
            ? `${URL.image}/activity/${fullList.items[0].id}/1080x1080/${fullList.items[0].slug}.jpg`
            : `${URL.image}/destination/${currentDestination.id}/1080x1080/${currentDestination.slug}.jpg`;

    const analytics = {
        listName:
            `${currentDestination.name}, ${currentDestination.state.code}` +
            (currentCategory ? ` - ${currentCategory.name}` : ''),
        categoryName:
            currentDestination.name + (currentCategory ? ` - ${currentCategory.name}` : ''),
    };

    const schemaItemList = useMemo(() => {
        const itemListElement = fullList.items.slice(0, 24).map((v, i) => {
            return {
                '@type': 'ListItem',
                'position': ++i,
                'name': v?.name,
                'url': buildRootUrl(v.url),
                'description': v.description,
                'image': `${URL.image}/activity/${v.id}/480x240/${v?.slug}.jpg`,
            };
        });
        return JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'ItemList',
            'name': `THINGS TO DO IN ${analytics.listName}`,
            'description': meta?.description || '',
            'itemListOrder': 'http://schema.org/ItemListOrderAscending',
            itemListElement,
        });
    }, [analytics.listName, fullList.items, meta?.description]);

    useEffect(() => {
        if (location) {
            trackAttractions(currentDestination, currentCategory);
        }
    }, [currentCategory, currentDestination, location]);

    return (
        <Layout breadcrumbs={breadcrumbs}>
            <AttractionsPageMeta
                pageMeta={meta}
                pageDestination={pageDestination}
                pageCategory={currentCategory ?? undefined}
                openGraphImage={openGraphImage}
                schemaItemList={schemaItemList}
                noindex={!fullList.total}
            />
            <AttractionsList
                faq={faq}
                title={title}
                isEmpty={isEmpty}
                fullList={fullList}
                currentDestination={currentDestination}
                currentCategory={currentCategory}
                featuredCategories={featuredCategories}
                featuredActivitiesTitle={featuredActivitiesTitle}
                listNameGA3={listNameGA3}
                isCategoryPage={!!currentCategory}
                landmarks={landmarks}
                categoryDescription={categoryDescription}
                availableAttributes={availableAttributes}
                noRecommends={!!noRecommends}
                placeholders={placeholders}
            />
        </Layout>
    );
};
