import { memo } from 'react';
import Head from 'next/head';
import { attractionsUrl, buildRootUrl } from '@/common/app/utils/urlsUtils';
import { isProd } from '@/common/app/constants/envs';
import { IDestinationCategory } from '@/entities/Destination/domain/Destination.domain';

type AttractionsPageProps = {
    pageMeta: {
        title: string;
        description: string;
    };
    pageDestination: {
        slug: string;
        url_search: string;
        name: string;
        stateCode: string;
    };
    pageCategory?: IDestinationCategory;
    schemaItemList?: string;
    openGraphImage: string;
    noindex?: boolean;
};

const AttractionsPageMeta = ({
    pageMeta,
    pageDestination,
    pageCategory,
    schemaItemList,
    openGraphImage,
    noindex,
}: AttractionsPageProps) => {
    return (
        <Head>
            {pageMeta && pageMeta.title && <title>{pageMeta.title}</title>}
            {pageMeta && pageMeta.description && (
                <meta name="description" key="description" content={pageMeta.description} />
            )}
            {pageDestination && pageCategory && (
                <link
                    rel="canonical"
                    key="canonical"
                    href={buildRootUrl(
                        attractionsUrl({
                            destination: pageDestination.slug,
                            stateCode: pageDestination.stateCode,
                            category: pageCategory.slug,
                        })
                    )}
                />
            )}
            {pageDestination && !pageCategory && (
                <link
                    rel="canonical"
                    key="canonical"
                    href={buildRootUrl(pageDestination.url_search)}
                />
            )}
            {schemaItemList && (
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{ __html: schemaItemList }}
                />
            )}
            {pageDestination && (
                <meta
                    property="og:url"
                    content={buildRootUrl(
                        attractionsUrl({
                            destination: pageDestination.slug,
                            stateCode: pageDestination.stateCode,
                            category: pageCategory?.slug,
                        })
                    )}
                />
            )}
            {pageMeta && pageMeta.title && <meta property="og:title" content={pageMeta.title} />}
            {pageMeta && pageMeta.description && (
                <meta property="og:description" content={pageMeta.description} />
            )}
            {openGraphImage && <meta property="og:image" content={openGraphImage} />}
            {openGraphImage && <meta property="og:image:width" content="1080" />}
            {openGraphImage && <meta property="og:image:height" content="1080" />}
            <meta property="fb:app_id" content={isProd ? '1639232852890435' : '3049823561722669'} />
            <meta property="og:type" content="website" />
            {/* <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@tripshock" /> */}
            {noindex && <meta name="robots" content="noindex" />}
        </Head>
    );
};

export default memo(AttractionsPageMeta);
