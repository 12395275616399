import { useState } from 'react';
import * as S from './AttractionsDescription.styles';
import { Container } from '@/common/ui/containers/Container';
import ReactMarkdown from 'react-markdown';

export const AttractionsDescription = ({
    title,
    description,
}: {
    title: string;
    description?: string;
}) => {
    const [readMore, setReadMore] = useState(false);

    return (
        <Container>
            <S.Title data-test-id="attraction_title">{title}</S.Title>
            {description && (
                <>
                    <S.AttractionDescription
                        data-test-id="attraction_description"
                        readMore={readMore}
                    >
                        <ReactMarkdown>{description}</ReactMarkdown>
                    </S.AttractionDescription>

                    <S.ReadMore onClick={() => setReadMore(!readMore)} isActive={readMore}>
                        Read more
                        <S.Arrow isActive={readMore} />
                    </S.ReadMore>
                </>
            )}
        </Container>
    );
};
