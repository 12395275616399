import styled, { css } from 'styled-components';
import Text from '@/styles/Themes/Text';
import { cssVars } from '@/styles/Themes/cssVars';
import { radius } from '@/styles/Themes/Metrics';
import Palette from '@/styles/Themes/Palette';
import Screen from 'src/styles/Themes/Screen';
import { DropdownArrow } from '@/shared/images/icons/DropdownArrow';

export const Container = styled.div`
    min-width: 50%;
    border-radius: ${radius.small};
    background-color: ${cssVars.bg_ashDarker_blueDark};
    padding: 16px;
    margin-bottom: 76px;

    ${Screen.desktop} {
        width: 50%;
    }
`;

export const Title = styled.h3`
    color: ${cssVars.fg_gray1_white};
    margin: 0;
    ${Text.Full.heading2}
`;

export const Grid = styled.div<{ count: number }>`
    display: grid;
    margin: 16px 0;
    grid-gap: 16px;

    ${({ count }) => {
        if (count > 2) {
            return css`
                grid-template: 240px 190px / 1fr 1fr;

                *:first-child {
                    grid-column-start: 1;
                    grid-column-end: 3;
                }
            `;
        }

        if (count > 1) {
            return css`
                grid-template: 240px 190px / 1fr;
            `;
        }

        return '';
    }}
`;

export const LinkMore = styled.a`
    color: ${Palette.green.normal};
    ${Text.Full.large.semibold}
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    ${Screen.desktop} {
        text-decoration: underline;
    }
    :active,
    :hover {
        color: ${Palette.green.light};
    }
    transition: 0.5s;
`;

export const Arrow = styled(DropdownArrow)`
    height: 18px;
    width: 18px;
    transform: rotate(-90deg);
    fill: ${Palette.green.normal};
    margin: 0 4px;
    ${Screen.ipad} {
        height: 20px;
        width: 20px;
    }
`;
