import { ReactElement } from 'react';
import * as S from './TopLandmarks.styles';
import { TLandmarksShort } from '@/screens/LandmarkMainPage/domain/Landmarks.domain';
import { LandmarkCard } from '@/screens/LandmarkMainPage/ui/LandmarkCard';
import Link from 'next/link';

type TopLandmarksProps = {
    destinationName: string;
    destinationSlug: string;
    stateCode: string;
    landmarks?: TLandmarksShort;
};

const CARDS_LIMIT = 3;

export const TopLandmarks = ({
    landmarks,
    destinationName,
    destinationSlug,
    stateCode,
}: TopLandmarksProps): ReactElement => {
    if (!landmarks || !landmarks.length) {
        return <></>;
    }

    const url = `/destination/${stateCode}/${destinationSlug}/landmarks/`.toLowerCase();

    return (
        <S.Container>
            <S.Title>{`Top Landmarks in ${destinationName}, ${stateCode}`}</S.Title>
            <S.Grid count={landmarks.length}>
                {landmarks.slice(0, CARDS_LIMIT).map((landmark) => (
                    <LandmarkCard
                        destinationSlug={destinationSlug}
                        stateCode={stateCode}
                        item={landmark}
                        key={landmark.id}
                        noMinHeight
                    />
                ))}
            </S.Grid>
            {landmarks.length > 1 && (
                <Link href={url} passHref>
                    <S.LinkMore>
                        More Landmark Guides
                        <S.Arrow />
                    </S.LinkMore>
                </Link>
            )}
        </S.Container>
    );
};
