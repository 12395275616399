import { cssVars } from '@/styles/Themes/cssVars';
import Palette from '@/styles/Themes/Palette';
import Screen from '@/styles/Themes/Screen';
import Text from '@/styles/Themes/Text';
import styled from 'styled-components';
import { radius } from 'src/styles/Themes/Metrics';

export const Title = styled.h1`
    color: ${cssVars.fg_gray1_white};
    ${Text.Full.heading2}
    margin: 0 0 10px;
    color: ${cssVars.fg_gray1_white};

    ${Screen.desktop} {
        margin: 26px 0 10px;
    }
`;

export const EmptyTourMessageContainer = styled.div`
    border-radius: ${radius.small};
    background-color: ${cssVars.bg_ash_dark2};
    text-align: center;
    margin: 35px auto 25px;
    padding: 16px;
    width: 100%;
`;

export const EmptyTourMessage = styled.div`
    ${Text.Mobile.caption.medium};
    color: ${cssVars.fg_gray2_gray3};

    ${Screen.ipad} {
        ${Text.Full.large.medium};
    }
`;

export const EmptyTourLink = styled.a`
    display: block;
    ${Text.Full.large.semibold};
    cursor: pointer;
    color: ${Palette.green.normal};
    &:hover {
        text-decoration: underline;
    }
`;

export const ImageContainer = styled.div`
    background-image: ${cssVars.empty_attraction_mob};
    width: 100%;
    height: 217px;
    margin: 50px auto;

    ${Screen.ipad} {
        width: 578px;
        height: 417px;
        margin: 60px auto;
        background-image: ${cssVars.empty_attraction_ipad};
    }

    ${Screen.desktop} {
        width: 646px;
        height: 466px;
        background-image: ${cssVars.empty_attraction_desktop};
    }
`;
