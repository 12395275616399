import { ReactElement } from 'react';
import * as S from './EmptyAttractionsPage.styles';
import Link from 'next/link';
import { Container } from '@/common/ui/containers/Container';

type EmptyAttractionsProps = {
    redirectToDestination: string;
    isCategoryPage: boolean | undefined;
    destinationName: string;
};

const EmptyAttractionsPage = ({
    redirectToDestination,
    isCategoryPage,
    destinationName,
}: EmptyAttractionsProps): ReactElement => {
    return (
        <Container>
            <S.Title>{destinationName}</S.Title>
            <S.EmptyTourMessageContainer>
                {isCategoryPage ? (
                    <>
                        <S.EmptyTourMessage>
                            There are no tours available for this activity
                        </S.EmptyTourMessage>
                        <Link href={redirectToDestination} passHref>
                            <S.EmptyTourLink>Click here</S.EmptyTourLink>
                        </Link>
                        <S.EmptyTourMessage>
                            To find available tours and attractions in this destination
                        </S.EmptyTourMessage>
                    </>
                ) : (
                    <>
                        <S.EmptyTourMessage>
                            There are no tours available for this date range
                        </S.EmptyTourMessage>
                    </>
                )}
            </S.EmptyTourMessageContainer>
            <S.ImageContainer />
        </Container>
    );
};

export { EmptyAttractionsPage };
export default EmptyAttractionsPage;
